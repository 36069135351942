body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fbf9f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td{
  padding: 3px;
}

#root{
  display: flex;
  flex-flow: column;
  justify-content: center;
}

#page-header, #page-content{
  display: flex;
  justify-content: center;
}

#header-menu, #product-content{
  width: 800px;
  display: flex;
  flex-flow: column;
}

.rs-navbar.rs-navbar-default, #page-header{
  background-color: #fcbe38;
}

.embalagem-img{
  width: 200px;
  margin: 5px;
}

/* .embalagem-img:hover{
  width: 350px;
  margin: 5px;
} */

#embalagens-imgs{
  display: flex;
}

.embalagem-content{
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-flow: column;
  align-items: center;
}

#materia-prima{
  display: flex;
}

#composicao{
  width: 60%;
}

#niveis-garantia table{
  border: 1px solid #000;
  border-radius: 10px;
}

#niveis-garantia table tr{
  background-color: #fbf9f7;
}

#niveis-garantia table tr:nth-child(odd){
  background-color: #e2e2e2;
}

#niveis-garantia table tr td:nth-child(2){
  text-align: right;
}

.varejo-descricao{
  font-weight: bold;
}

div#page-footer {
  background-color: #fcbe38;
  font-size: 12px;
  min-height: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}



@media (max-width: 799px){
  #header-menu, #product-content{
    width: 100%;
    padding: 0 5px;
  }

  #materia-prima{
    flex-flow: column;
  }

  #composicao{
    width: 100%;
  }

  #embalagens-imgs{
    flex-flow: column;
  }

  .embalagem-img{
    width: 350px;
  }

  .embalagem-content {
    margin-bottom: 15px;
  }

  #niveis-garantia table {
    width: 100%;
  }
}